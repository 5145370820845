import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';

class ProductionMasterService {
    readProductionMasterQuery(){
        var query = `
            query ($paging:ServerPaging, $status:[String!]) {
                GetMasterProductions (Paging:$paging, Statuses:$status) {
                    productions {
                        created_at
                        last_update
                        production_id
                        production_code
                        production_planning_id
                        production_planning_code
                        production_schedule_id
                        production_schedule_order
                        production_schedule_code
                        production_schedule_date
                        production_type
                        status
                        machine_id
                        machine_code
                        machine_name
                        item_id
                        item_code
                        item_name
                        formula_id
                        production_qty
                        approved_qty
                        production_start_dt
                        production_end_dt
                        production_estimation_dt
                    }
                    total
                }
            }
        `;
        return query;
    }

    readMaintenanceQuery(){
        var query = `
        query ($id:Int, $paging:ServerPaging) {
            GetMaintenance (productionID:$id, Paging:$paging) {
                maintenance {
                    maintenance_id
                    maintenance_code
                    maintenance_date
                    status
                    machine_name
                    machine_code
                    downtime
                    notes
                }
                total
            }
        }
        `;
        return query;
    }

    async getProductionMasterQuery(id){
        const variables = {
            id : [id]
        }
        var query = gql`
            query ($id:[Int!]) {
                GetMasterProductions (ProductionIDs:$id) {
                    productions {
                        created_at
                        last_update
                        production_id
                        production_code
                        production_planning_id
                        production_planning_code
                        production_schedule_id
                        production_schedule_order
                        production_schedule_code
                        production_schedule_date
                        production_type
                        status
                        machine_id
                        machine_code
                        machine_name
                        item_id
                        item_code
                        item_name
                        base_uom
                        secondary_uom
                        secondary_conv_amount
                        production_qty
                        approved_qty
                        secondary_production_qty
                        secondary_approved_qty
                        formula_id
                        production_start_dt
                        production_end_dt
                        production_estimation_dt
                        total_result_weight
                        total_waste_weight
                        total_material_weight
                        total_used_material_weight
                        total_lost_weight
                        total_production_weight
                        detail_materials {
                            created_at
                            last_update
                            production_detail_material_id
                            production_id
                            material_id
                            material_code
                            material_name
                            requested_quantity
                            additional_quantity
                            used_quantity
                        }
                        production_results {
                            created_at
                            last_update
                            production_mutation_id
                            production_id
                            mutation_item_type
                            status
                            item_id
                            item_name
                            product_code
                            base_uom
                            secondary_uom
                            secondary_conv_amount
                            requested_quantity
                            total_approved_quantity
                            secondary_requested_quantity
                            secondary_total_approved_quantity
                        }
                        production_mutations {
                            created_at
                            last_update
                            production_mutation_id
                            production_id
                            mutation_item_type
                            status
                            item_id
                            item_name
                            product_code
                            base_uom
                            secondary_uom
                            secondary_conv_amount
                            requested_quantity
                            total_approved_quantity
                            secondary_requested_quantity
                            secondary_total_approved_quantity
                        }
                        maintenances {
                            created_at
                            last_update
                            maintenance_id
                            maintenance_code
                            maintenance_date
                            status
                            machine_id
                            machine_name
                            machine_code
                            storage_id
                            production_id
                            downtime
                            has_downtime
                            created_by
                            approved_by
                            notes
                            additional_notes
                            approval_notes
                            maintenance_item_usage {
                            created_at
                            last_update
                            maintenance_item_usage_id
                            maintenance_id
                            item_id
                            item_name
                            product_code
                            quantity
                            base_uom
                            }
                        }
                    }
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        return result.data.GetMasterProductions.productions[0];
    }
    
    readMaterialQuery(){
        var query = `query($paging:ServerPaging, $formulaId:Int!){
            GetFormulaItemList(Paging:$paging, FormulaId:$formulaId ) {
                        ItemStock {
                        storage_id
                        storage_name
                        item_id
                        product_code
                        item_name
                        barcode
                        item_type
                        type_detail_id
                        type_detail_name
                        base_uom
                        weight
                        stock
                    }
                    Total
                }
            }`;
        return query;
    }

    readWasteItemQuery(){
        var query = `
            query ($paging:ServerPaging) {
                GetQualityControlItem (Paging:$paging) {
                    quality_control_item{
                        item_id
                        item_name
                        product_code
                        type_detail_id
                        type_detail_name
                    }
                    total
                }
            }
        `;
        return query;
    }

    async editMasterProductionQuery(variables){
        var query = gql`
            mutation ( $id:Int!, $data:NewMasterProduction! ) {
                UpdateMasterProduction ( ID:$id, New:$data ){
                    message
                }
            }
        `;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    generateMaterialDetail(data){
        var material = [];

        if(data != null){
            for (let i = 0; i < data.length; i++) {
                var requestQuantity = data[i].requested_quantity == null ? 0 : data[i].requested_quantity;
                var additionalQuantity = data[i].additional_quantity == null ? 0 : data[i].additional_quantity;
                var usedQuantity = data[i].used_quantity == null ? 0 : data[i].used_quantity;
                var totalQuantity = requestQuantity + additionalQuantity;

                var str = { 
                    production_detail_material_id: data[i].production_detail_material_id,
                    production_id: data[i].production_id,
                    material_id: data[i].material_id,
                    material_code: data[i].material_code,   
                    material_name: data[i].material_name,
                    requested_quantity: requestQuantity,
                    additional_quantity: additionalQuantity,
                    total_quantity: totalQuantity,
                    used_quantity: usedQuantity
                }
                material.push(str);
            }
        }

        return material;
    }

    convertMaterialSave(data){
        var result = [];
        
        if(data != null){
            for (let i = 0; i < data.length; i++) { 
                var str = { 
                    production_mutation_item_id: null,
                    production_mutation_id: null,
                    storage_id: data[i].storage_id,
                    item_id: data[i].material_id,
                    item_type: "Request Material",
                    requested_quantity: data[i].quantity,
                    total_approved_quantity: null,
                    item_details: null
                }
                result.push(str);
            }
        }

        return result;
    }

    convertMasterMaterialSave(data){
        var result = [];
        
        if(data != null){
            for (let i = 0; i < data.length; i++) { 
                var str = { 
                    production_detail_material_id: data[i].production_detail_material_id,
                    production_id: data[i].production_id,
                    material_id: data[i].material_id,
                    requested_quantity: data[i].requested_quantity,
                    used_quantity: data[i].used_quantity
                }
                result.push(str);
            }
        }

        return result;
    }

    async addRequestMaterialQuery(variables){
        var query = gql`
            mutation ( $data:NewProductionMutation! ) {
                RequestAdditionalProductionMaterial ( New:$data )
            }
        `;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async addQCQuery(variables){
        var query = gql`
            mutation ( $id:Int, $quantity:Float, $mutationId:Int, $data:[NewProductionMutationItem!]) {
                CreateQualityControlItem ( ProductionID:$id, Quantity:$quantity, ProductionMutationID:$mutationId, New:$data)
            }
        `;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async addMaintenanceQuery(variables){
        var query = gql`
            mutation ( $data:NewMasterMaintenance! ) {
                CreateMaintenance ( New:$data )
            }
        `;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    duplicateMaterialChecker(oldData, newData){
        var duplicate = 0;
        for (let i = 0; i < oldData.length; i++){
            if((oldData[i].material_id == newData.material_id) && (oldData[i].storage_id == newData.storage_id)){
                duplicate++;
            }
        }
        return duplicate==0 ? false : true;
    }

    itemDataObject(data){
        var dataItem = [{
            item_id: data.item_id,
            product_code: data.product_code,
            item_name: data.item_name,
            type_detail_id: data.type_detail_id,
            type_detail_name: data.type_detail_name,
            quantity: data.quantity,
        }];
        
        return dataItem;
    }
    
    itemMaterialDataObject(data){
        var dataItem = [{
            storage_id: data.storage_id,
            storage_name: data.storage_name,
            item_id: data.item_id,
            product_code: data.product_code,
            item_name: data.item_name,
            type_detail_id: data.type_detail_id,
            type_detail_name: data.type_detail_name,
            quantity: data.quantity,
        }];
        
        return dataItem;
    }

    duplicateItemChecker(oldData, newData){
        var duplicate = 0;
        for (let i = 0; i < oldData.length; i++){
            if(oldData[i].item_id == newData.item_id){
                duplicate++;
            }
        }
        return duplicate==0 ? false : true;
    }

    convertWasteItemSave(data){
        var result = [];
        
        if(data != null){
            for (let i = 0; i < data.length; i++) { 
                var str = { 
                    production_mutation_item_id: null,
                    production_mutation_id: null,
                    storage_id: null,
                    item_id: data[i].item_id,
                    item_type: "Waste Produksi",
                    requested_quantity: data[i].quantity,
                    total_approved_quantity: null,
                    item_details: null
                }
                result.push(str);
            }
        }

        return result;
    }
}

export default new ProductionMasterService();